<footer class="main-footer clearfix">
  <ul class="download-on clearfix">
    <li>
      <a href="https://play.google.com/store/apps/details?id=com.mia.production">
        <figure>
          <img src="assets/img/google-play-badge.png" alt="" class="badge-icon" />
        </figure>
      </a>
    </li>
    <li>
      <a href="https://apps.apple.com/us/app/mialergia/id1546809130">
        <figure>
          <img src="assets/img/apple-app-store-badge_es.svg" alt="" class="badge-icon" />
        </figure>
      </a>
    </li>
  </ul>
  <ul class="logos clearfix">
    <li>
      <figure>
        <img src="assets/img/udelar.png" alt="" class="logo-icon" />
      </figure>
    </li>
    <li>
      <figure>
        <img src="assets/img/ei-logo.png" alt="" class="logo-icon" />
      </figure>
    </li>
    <li>
      <figure>
        <img src="assets/img/hospital-pasteur.jpg" alt="" class="logo-icon" />
      </figure>
    </li>
    <li>
      <figure class="compromiso-pasteur">
        <img src="assets/img/compromiso-pasteur.png" alt="" class="logo-icon" />
      </figure>
    </li>
  </ul>
</footer>
