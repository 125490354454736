<app-header></app-header>
<div class="hero">
  <figure class="hero-image-content">
    <img src="assets/img/hero.jpg" alt="hero image" />
  </figure>
</div>
<div class="page-content-wrapper content">
  <h2 class="">{{ title }}</h2>
  <p>
    MIA es una aplicación gratuita, pensada para asistir al usuario alérgico en el seguimiento de sus síntomas. Esta aplicación fue pensada
    y creada por profesionales de distintas áreas como la medicina, la palinología y la ingeniería.
  </p>

  <div class="carousel">
    <p-carousel [value]="images" [autoplayInterval]="5000" [numVisible]="numVisible" [numScroll]="numVisible" [circular]="true">
      <ng-template let-images pTemplate="item">
        <div class="product-item">
          <div class="product-item-content">
            <div class="p-mb-3">
              <img src="assets/img/photos/{{ images }}" [alt]="images" class="product-image" />
            </div>
          </div>
        </div>
      </ng-template>
    </p-carousel>
  </div>
  <p>
    Descargando la aplicación MIA podrás ver los niveles de concentración de polen y esporas de hongos en el aire de Montevideo, los mismos
    se muestran para los grupos, árboles, hierbas y hongos, y podrán ser calificados como alto, medio, o bajo. Esta calificación será
    visualmente distinguida mediante el uso de los colores del semáforo. La información sobre los niveles de polen y esporas de hongos es
    recolectada diariamente por docentes del Laboratorio de Palinología de la Facultad de Ciencias de la Udelar, en el barrio de Malvín
    Norte.

    <br /><br />
    Los niveles de polen o esporas de hongos de los distintos tipos de alérgenos que componen cada grupo, podrán ser revisados en detalle
    mediante el botón de más info presente en cada grupo. Se reportan aquellos tipos polínicos y fúngicos potencialmente alergénicos, que
    son utilizados por el personal médico en los prick test de alergenicidad. Por lo tanto, aquellos pacientes que se hayan realizado estos
    test, podrán ingresar en su perfil sus alergias. Habiéndose ingresado alergias por parte del usuario, la aplicación alertará de forma
    personalizada, en el caso de que una de estas alergias se vea afectada por las concentraciones de polen o esporas de hongos en el aire.

    <br /><br />
    MIA también cuenta con un amigable diario de síntomas, donde el usuario podrá ingresar cada vez que desee cómo se sintió en el día. El
    alta de un evento en el diario de síntomas, consta de responder una serie de preguntas, pudiendo también agregar un comentario si el
    usuario lo desea. Las preguntas tienen respuestas predefinidas y el usuario deberá elegir una de estas. Al terminar de responder las
    preguntas y en caso de que estas indiquen que el usuario está ante una crisis, se le mostrará una alerta, cómo por ejemplo, recomendando
    que inicie el tratamiento recetado o que visite a su médico. Cabe destacar que las preguntas y respuestas presentes en el diario de
    síntomas fueron diseñadas por una comunidad de médicos alergistas. Todos los síntomas ingresados en el diario serán listados y mostrados
    al usuario haciendo uso de los colores del semáforo para que el usuario pueda identificar fácilmente qué días se sintió mejor o peor,
    pudiendo consultar qué datos ingresó en cada uno de ellos.

    <br /><br />
    Los niveles altos y medios de concentración utilizados para generar las alertas aeropalinológicas de la presente aplicación, están
    basados en estudios realizados en Montevideo y en los niveles de concentración máximos definidos internacionalmente para cada tipo de
    polen o espora. Cabe señalar que las concentraciones de polen y esporas de hongos en el aire pueden diferir localmente por barrio o zona
    de la ciudad, dependiendo de las plantas que se encuentren en cada área, el grado de urbanización y la presencia de terrenos baldíos o
    sin mantenimiento. Además, la concentración de las partículas en el aire también puede verse influida por la velocidad y dirección del
    viento, por las condiciones de humedad del aire y el comportamiento espacio-temporal de las lluvias.

    <br /><br />
    Por otra parte, la susceptibilidad de cada persona a los tipos polínicos y fúngicos reportados en esta aplicación debe ser determinada a
    nivel individual por parte de un especialista médico (alergista u otorrinolaringólogo). Por ello, la información que se brinda en la
    aplicación es de carácter meramente orientativo y no representa un indicador de riesgo de sufrir rinitis alérgica por parte de las
    personas sensibles a estos alérgenos.
    <br /><br />

    MIA también cuenta con un sistema de notificaciones que el usuario podrá activar si así lo desea. Una de ellas permite recibir un
    recordatorio diario para agregar un nuevo evento al diario de síntomas, la otra notificaca al usuario cada vez que se actualicen los
    niveles de polen y esporas de hongos.

    <br /><br />
    En MIA también podrá consultar fichas informativas sobre los distintos alérgenos reportados, que son aquellos detectados en los prick
    tests. Estas fichas fueron diseñadas para orientar a los pacientes en el reconocimiento de las plantas cuyo polen les causa alergia, así
    como también, brindar algunas medidas preventivas.

    <br /><br />
    Ante cualquier duda, puede contactar con el investigador responsable desde la opción <strong>{{ menu.contactUs }}</strong
    >. <br /><br />
    Por más información acerca de la aeropalinología de la ciudad de Montevideo (estudio de polen y esporas en el aire) visite
    <a href="http://polenyesporas.fcien.edu.uy/">http://polenyesporas.fcien.edu.uy/</a>.
  </p>
  <br />
</div>
<app-footer></app-footer>
