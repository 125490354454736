<app-header></app-header>
<div class="hero">
  <figure class="hero-image-content">
    <img src="assets/img/hero.jpg" alt="hero image" />
  </figure>
</div>
<div class="page-content-wrapper content">
  <h2 class=""></h2>
  <p>
    MIA fue financiado por el <a href="https://www.ei.udelar.edu.uy/">Espacio Interdisciplinario (EI)</a> de la Universidad de la República,
    área que busca apoyar y financiar proyectos que requieran de diferentes disciplinas para su elaboración. Un espacio interdisciplinario
    es aquel que se compone de varias disciplinas científicas o culturales o en su defecto, es el resultado de varias de ellas, es decir,
    involucra a más de una disciplina o materia en su elaboración, hecho por el cual dispondrá de varios enfoques y de una visión ampliada
    del tema o problemática que se trata.

    <br /><br />
    Nuestro proyecto fue presentado y aprobado para el
    <a href="https://www.ei.udelar.edu.uy/sites/default/files/inline-files/Bases_semilleros_.pdf">plan de Semillero</a>, el cual tiene como
    propósito facilitar las fases de gestación, elaboración e implementación de propuestas de trabajo que puedan conducir en el futuro a la
    conformación de núcleos y/o centros interdisciplinarios.

    <br /><br />
  </p>

  <div class="team">
    <h2 class="">{{ title }}</h2>
    <p>MIA se compone por áreas de la medicina, biología e ingeniería.</p>

    <ul class="clearfix">
      <h3>Equipo de Medicina</h3>
      <li *ngFor="let member of doctorsTeam">
        <figure [ngClass]="{silhouette: !member.image}">
          <img
            src="assets/img/team/{{ member.image ? member.image : 'doctor.svg' }}"
            [alt]="member.image ? member.image : 'woman-1.svg'"
            class="member-image"
          />
        </figure>
        <p class="name">{{ member.name }}</p>
        <p class="description">{{ member.description }}</p>
      </li>
    </ul>
    <ul class="clearfix">
      <h3>Equipo de Biología</h3>
      <li *ngFor="let member of biologyTeam">
        <figure [ngClass]="{silhouette: !member.image}">
          <img
            src="assets/img/team/{{ member.image ? member.image : 'doctor.svg' }}"
            [alt]="member.image ? member.image : 'woman-1.svg'"
            class="member-image"
          />
        </figure>
        <p class="name">{{ member.name }}</p>
        <p class="description">{{ member.description }}</p>
      </li>
    </ul>
    <ul class="clearfix">
      <h3>Equipo de Ingeniería</h3>
      <li *ngFor="let member of engineerTeam">
        <figure [ngClass]="{silhouette: !member.image}">
          <img
            src="assets/img/team/{{ member.image ? member.image : 'doctor.svg' }}"
            [alt]="member.image ? member.image : 'woman-1.svg'"
            class="member-image"
          />
        </figure>
        <p class="name">{{ member.name }}</p>
        <p class="description">{{ member.description }}</p>
      </li>
    </ul>
  </div>
</div>

<app-footer></app-footer>
