<app-header></app-header>
<div class="hero">
  <figure class="hero-image-content">
    <img src="assets/img/hero.jpg" alt="hero image" />
  </figure>
</div>
<div class="page-content-wrapper content proyecto">
  <h2 class="">Proyecto</h2>
  <p>
    En base a los requerimientos planteados por parte de los integrantes de la Facultad de Ciencias y los médicos del Hospital Pasteur, se
    decidió desarrollar una aplicación que permitiera a los usuarios conocer los niveles de polen de árboles y hierbas y esporas de hongos,
    así cómo también, tener un diario de síntomas. Para almacenar tanto los datos de pólenes y esporas, como los nuevos síntomas dados de
    alta por parte de los usuarios fue necesario utilizar una base de datos en conjunto con un servidor para procesar los datos almacenados.
  </p>

  <h3 class="">Backend</h3>
  <p>
    Para la implementación del backend utilizamos Django. Django es un framework basado en python, libre y open-source. El backed provee una
    api web, utilizada para registrar usuarios, obtener el último reporte de polen y esporas de hongos, dar de alta nuevos síntomas, entre
    otras funciones. También provee una admin page, que es utilizada, entre otras cosas, para dar de alta nuevos reportes de palinológicos.
    <br />
  </p>

  <h3 class="">Frontend</h3>
  <p>
    Para el frontend decidimos utilizar React Native, el mismo es un framework open-source, que combina el desarrollo nativo de los sistemas
    operativos de iOS y Android en simultáneo, junto con React, una librería de Javascriptespecialmente pensada para crear interfaz de
    usuario.
    <br /><br />
  </p>

  <p>
    El codigo del proyecto fue alojado en repositorios de github, al cual se puede acceder de forma libre, para consultarlos visite:
    <a href="https://github.com/mialergia">https://github.com/mialergia</a>
  </p>
</div>
<app-footer></app-footer>
