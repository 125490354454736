export const TITLE = 'MIA';
export const TEAM_TITLE = 'Equipo';

export const MENU = {
  contactUs: 'Contáctenos',
  project: 'proyecto',
  team: 'equipo',
};

export const IMAGES = [
  'IMG_4009.png',
  'IMG_4028.png',
  'IMG_4029.png',
  'IMG_4030.png',

  'IMG_4031.png',
  'IMG_4044.png',
  'IMG_4033.png',

  'IMG_4034.png',
  'IMG_4035.png',
  'IMG_4036.png',

  'IMG_4037.png',
  'IMG_4038.png',
  'IMG_4039.png',

  'IMG_4040.png',

  'IMG_4020.png',
  'IMG_4021.png',

  'IMG_4045.png',
  'IMG_4046.png',
  'IMG_4048.png',
];

export const DOCTORS_TEAM = [
  {name: 'Mariana Bonifacino', description: 'Doctora, especialista en Alergología', image: 'mariana-bonifacino.png'},
  {name: 'Rosario Eugui', description: 'Doctora, especialista en Otorrinolaringología', image: 'rosario-eugui.png'},
  {name: 'Patricia Indarte', description: 'Doctora, especialista en Otorrinolaringología', image: 'patricia-indarte.png'},
  {name: 'Carina Almirón', description: 'Doctora, especialista en Otorrinolaringología', image: 'doctor.svg'},
];

export const BIOLOGY_TEAM = [
  {name: 'Ángeles Beri', description: 'Doctora en Ciencias Biológicas y responsable del proyecto', image: 'angeles-beri.png'},
  {name: 'Ximena Martínez', description: 'Doctora en Ciencias Biológicas Otorrinolaringología.', image: 'scientist.svg'},
  {name: 'Alejandra Leal', description: 'Doctorado en Ciencias Biológicas', image: 'scientist.svg'},
];

export const ENGINEER_TEAM = [
  {
    name: 'Libertad Tansini ',
    description: 'Doctora en Ciencias de la Computación y responsable del proyecto',
    image: 'libertad-tansini.png',
  },
  {name: 'Regina Motz', description: 'Doctora en Ciencias de la Computación', image: 'regina-motz.png'},
  {name: 'Tamara Suárez', description: 'Estudiante de Ingeniería en Computación', image: 'tamara-suarez.png'},
  {name: 'Natalia Campiglia ', description: 'Estudiante de Ingeniería en Computación', image: 'natalia-campiglia.png'},
];
